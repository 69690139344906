@import '../partials/variables';

.header {
    @apply flex;
    @apply justify-center;
    @apply items-center;
    @apply bg-transparent;
    height: 80px;
    width: 100%;
    position: fixed;
    z-index: 999999;
    @apply pl-4;
    @apply pr-6;
    @apply md:pl-12;
    @apply md:pr-12;

    nav.header__nav {
        @apply flex;
        @apply flex-wrap;
        @apply justify-between;
        @apply items-center;

        .hamburger {
            @apply block;
            @apply lg:hidden;
            @apply text-white;
            mix-blend-mode: difference;
        }

        ul.header__nav-menu {
            @apply opacity-0;
            @apply invisible;
            @apply fixed;
            @apply pt-20;
            @apply top-0;
            @apply left-0;
            @apply w-full;
            @apply h-screen;

            @apply lg:h-auto;
            @apply lg:w-auto;
            @apply lg:visible;
            @apply lg:opacity-100;
            @apply lg:pt-0;
            @apply lg:relative;
            @apply lg:flex;
            @apply justify-end;
            @apply flex-nowrap;


            li.nav-menu__item {
                @apply pl-8;
                @apply py-4;

                a {
                    font-size: 13px;
                    @apply font-bold;
                    @apply text-white;
                    mix-blend-mode: difference;
                }

                &:hover {
                    a {
                        @apply text-purple;
                        mix-blend-mode: normal;
                    }
                }
            }
        }
    }

    .logo-wrapper {
        z-index: 99999;

        .header__logo {
            height: 33px;
            width: 33px;
        }
    }
    
    

}