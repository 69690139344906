$pink: #FD66A6;
$orange: #FF7F50;
$purple: #7E2AFD;
$yellow: #FFC100;
$blue: #42A2D8;
$green: #64C88C;
$gray: #A9A9A9;
$ultramarine: #5363F1;

@mixin card-link {
    @apply block;
    @apply absolute;
    @apply h-full;
    @apply w-full;
    z-index: 10;
}

@mixin feature-card {

    @apply relative;
    font-size: 14px;

    a {
        @include card-link;
    }

    & .feature-image,
    & .feature-title,
    & span svg {
        @apply transition-all;
        @apply duration-200;
        @apply ease-in-out;
    }

    .feature-image {
        @apply md:pl-8;
        @apply h-24;
        @apply md:h-32;
        @apply flex;
        @apply items-center;
        @apply md:justify-end;
        @apply mb-4;
        @apply md:mb-9;
        @apply transform;

        img {
            @apply block;
            @apply max-w-full;
            @apply max-h-full;
            @apply w-auto;
            @apply h-auto;
            @apply shadow-xl;
        }
    }

    

    &:hover .feature-image {
        @apply -translate-y-4;
    }

    .feature-title {
        @apply text-black;
        @apply font-semibold;
        @apply lg:font-extrabold;
        @apply text-xs;
        @apply lg:text-base;
        @apply mb-2;
        @apply md:mb-6;
        transition: all 200 ease-in-out;
    }

    &:hover .feature-title {
        @apply text-purple;
    }

    p {
        @apply text-gray;
        @apply font-semibold;
        @apply mb-2;
        @apply leading-6;
        @apply hidden;
        @apply md:block;
    }

    span {
        @apply block;
        @apply text-purple;
        
        svg {
            @apply transform;
        transition: all 200 ease-in-out;
        }
    }

    &:hover span svg {
        @apply translate-x-6;
    }

}

@mixin pricing-card {
    
    @apply relative;
    @apply bg-white;
    @apply text-black;
    @apply shadow-2xl;
    @apply flex;
    @apply flex-col;
    @apply items-center;
    @apply text-center;
    @apply px-6;
    @apply py-8;
    @apply lg:py-12;
    @apply w-full;
    @apply md:w-3/5;
    @apply lg:w-auto;
    @apply mb-6;
    @apply lg:mb-0;
    @apply text-center;
    @apply order-2;

    flex-basis: 32%;
    flex-grow: 1;

    @apply rounded-lg;

    .pricing-price {
        @apply text-h3;
        @apply lg:text-5xl;
        @apply text-ultramarine;
        @apply relative;
        @apply w-max;
        @apply font-semibold;

        &::before {
            content: '€';
            @apply block;
            @apply absolute;
            @apply font-semibold;
            @apply text-ultramarine;
            @apply text-xl;
            @apply lg:text-2xl;
            
            @apply -left-6;
            @apply lg:-left-7;
            @apply -top-1;
        }
    }

    .pricing-license {
        font-size: 16px;
        @apply text-gray;
        @apply uppercase;
        @apply font-bold;
        @apply mb-8;
        @apply lg:mb-12;
    }

    .pricing-title {
        font-size: 16px;
        @apply font-bold;
        @apply mb-4;
    }

    .pricing-features {
        @apply mb-6;
        @apply lg:mb-9;
        li {
            @apply opacity-75;
            @apply leading-6;
            font-size: 12px;
        }
    }

    .pricing-cta {
        @apply mb-4;
        min-width: 100px;
    }

    .pricing-trial {
        font-size: 11px;
    }


    &:first-child {
        @apply lg:rounded-r-none;
        @apply lg:pr-0;
    }

    &:last-child {
        @apply lg:rounded-l-none;
        @apply lg:pl-0;        
    }

    &:last-child, 
    &:first-child {
        .pricing-price {
            @apply text-h3;
    
            &::before {
                
                @apply text-xl;
                @apply -top-1;
                @apply -left-6;
            }
        }
    }
    


}

@mixin faq-card {
    
    .faq-question {
        @apply text-black;
        @apply font-extrabold;
        @apply text-sm;
        @apply lg:text-base;
        @apply mb-4;
    }

    .faq-answer {
        @apply text-gray;
        @apply font-semibold;
        @apply text-sm;
    }

}

@mixin overview-card {
    @apply flex;
    @apply flex-nowrap;
    @apply justify-between;
    @apply items-center;
    @apply shadow-2xl;
    @apply py-6;
    @apply pl-4;
    @apply pr-6;
    @apply cursor-pointer;
    @apply bg-white;
    @apply transform;

    @apply transition-all;
    @apply duration-200;
    @apply ease-in-out;

    @apply hover:-translate-y-4;

    a {
        @include card-link;
    }

    .card-icon {
        @apply mr-6;
        
        svg {
            height: 52px;
            width: 52px;
        }
    }
    
    div {
        flex-grow: 1;
        @apply mr-2;
        @apply text-black;

        .card-title {
            @apply font-extrabold;
            
            font-size: 14px;
        }

        span {
            @apply font-medium;
            font-size: 12px;
            line-height: 1.1em;
        }
    }

  }
