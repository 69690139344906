@import '../partials/variables';

.overview {

    /* webpackIgnore: true */
    background-image: url('/public/img/overview-bgi.png');
    background-size: max(1070px, 50vw);
    background-position: 50vw bottom;
    background-repeat: no-repeat;
    @apply pt-16;
    @apply pb-16;
    @apply lg:pt-32;
    @apply lg:pb-20;


    ul {

        @apply grid;
        @apply grid-cols-1;
        @apply md:grid-cols-2;
        @apply lg:grid-cols-3;
        @apply gap-4;
        @apply md:gap-12;
        @apply py-8;

        .overview-card {
            @include overview-card;
        }
    }
}