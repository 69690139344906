@import '../partials/variables';

.hero {
    position: relative;
    @apply min-h-screen;
    background: rgb(106,57,155);
    background: -moz-linear-gradient(45deg, rgba(106,57,155,1) 10%, rgba(106,57,155,1) 10%, rgba(73,159,211,1) 50%, rgba(92,106,241,1) 95%);
    background: -webkit-linear-gradient(45deg, rgba(106,57,155,1) 10%,rgba(106,57,155,1) 10%,rgba(73,159,211,1) 50%,rgba(92,106,241,1) 95%);
    background: linear-gradient(45deg, rgba(106,57,155,1) 10%,rgba(106,57,155,1) 10%,rgba(73,159,211,1) 50%,rgba(92,106,241,1) 95%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6a399b', endColorstr='#5c6af1',GradientType=1 );

    @apply overflow-hidden;
    @apply justify-start;
    @apply lg:justify-center;

    & > .container {
        @apply flex;
        @apply flex-wrap-reverse;
        @apply lg:flex-wrap;
        @apply items-start;
        @apply lg:items-center;
        @apply text-white;

    }

    @apply pt-16;
    @apply lg:pt-20;

    .hero-img {
        width: 100%;
        max-width: 100%;
    }

    .container {
        z-index: 10;
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-image: url('/public/img/hero-bgi.png');
        background-size: cover;
    }
}