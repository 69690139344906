@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer utilities {
    .bg-gradient-to-tr-45 {
        background-image: linear-gradient(45deg, var(--tw-gradient-stops));
      }	
  }

@import './variables';

@import url('https://fonts.googleapis.com/css2?family=Spartan:wght@200;300;400;500;600;700;800;900&display=swap');

html {
    @apply text-black;
    font-family: 'Spartan', sans-serif;
}

strong, b {
    @apply font-bold;
}

main {

    section {
        @apply flex;
        @apply flex-col;
        @apply justify-center;
        @apply px-4;
        @apply md:px-12;

        &.fullwidth {
            @apply px-0;
        }
    }
}

.button, button {
    border-radius: 5px;
    height: 40px;
    @apply flex;
    @apply items-center;
    @apply px-6;
    @apply font-bold;
    @apply justify-center;
    @apply transform;
    @apply transition-all;
    @apply duration-200;
    @apply ease-in-out;
    font-size: 13px;
    width: max-content;
}

.button:hover, button:hover {
    @apply shadow-lg;
}

.button.small, button.small {
    height: 25px;
    font-size: 11px;
}

.button-outlined {
    @apply bg-transparent;
    border-color: currentColor;
    @apply border;
}