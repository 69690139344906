@import '../partials/variables';

.features {

    .general-features, .mill-features, .lathe-features {
        @apply grid;
        @apply grid-cols-1;
        @apply md:grid-cols-3;
        @apply lg:grid-cols-4;
        @apply gap-6;
        @apply lg:gap-8;
        @apply mb-16;
        @apply md:mb-24;
        @apply lg:mb-32;

        &:last-child {
            @apply mb-0;
            @apply lg:mb-32;
        }
    }
    
    .features-sidebar {

        @apply md:border-r;
        border-color: #D9D9D9;

        & > div {
            @apply relative;
            @apply md:sticky;
            @apply md:top-32;
            @apply pr-0;
            @apply md:pr-6;
            @apply lg:pr-8;
        }

        h3 {
            @apply text-purple;
            @apply md:text-black;
            @apply text-lg;
            @apply md:text-2xl;
            @apply lg:text-h3;
            @apply font-extrabold;
            @apply mb-2;
            @apply md:mb-4;
            @apply lg:mb-8;
        }

        p {
            @apply leading-4;
            @apply lg:leading-8;
            @apply text-xs;
            @apply md:text-small;
            @apply font-semibold;
        }

    }

    .features-grid {

        @apply md:col-span-2;
        @apply lg:col-span-3;
        @apply grid;
        @apply grid-cols-2;
        @apply md:grid-cols-2;
        @apply lg:grid-cols-3;
        @apply gap-5;
        @apply md:gap-10;
        @apply sm:gap-8;

        .feature-card {
            @include feature-card;
        }

    }

}