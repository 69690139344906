@import '../partials/variables';

footer.footer {

    @apply px-4;
    @apply md:px-12;
    @apply text-white;
    background: rgb(83,99,241);
    background: -moz-linear-gradient(45deg,  rgba(83,99,241,1) 0%, rgba(66,162,216,1) 100%);
    background: -webkit-linear-gradient(45deg,  rgba(83,99,241,1) 0%,rgba(66,162,216,1) 100%);
    background: linear-gradient(45deg,  rgba(83,99,241,1) 0%,rgba(66,162,216,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5363f1', endColorstr='#42a2d8',GradientType=1 );


    .footer__widget {
        @apply flex;
        @apply flex-wrap;
        @apply pt-12;
        @apply pb-12;
        @apply lg:pt-28;
        @apply lg:pb-24;


        .widget {

            @apply mb-8;
            @apply lg:mb-0;

            &:last-child {
                @apply mb-0;
                @apply md:mb-8;
                @apply lg:mb-0;
            }

            .widget__title {
                font-size: 16px;
                @apply lg:text-small;
                @apply font-bold;
                @apply mb-4;
            }

            ul {
                
                &.footer-menu {
                    @apply grid;
                    @apply grid-cols-2;
                    @apply md:grid-cols-3;
                    @apply gap-x-4;
                    @apply gap-y-2;
                    @apply lg:block;
                }
                

                li {
                    font-size: 14px;
                    @apply pb-2;
                }
            }
        }
    }

    .colophon {
        min-height: 80px;
        @apply flex;
        @apply items-center;
        @apply pt-3;
        font-size: 12px;

        @apply pb-24;
        @apply lg:pb-0;
    }

    a {
        @apply hover:text-orange;
    }

}