@import '../partials/variables';

.difference {

    @apply pb-0;
    @apply pt-16;
    @apply lg:py-24;

    & > div {
        min-height: 612px;

        div {
            @apply lg:py-32;
            @apply pt-8;
            @apply pb-0;
        }
    }

    

}