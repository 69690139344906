@import '../partials/variables';

#download {
    background-color: #F1F1F1;
}

#download-modal {
    z-index: 999999999;

    .modal {
        max-height: 80vh;
    }
}
