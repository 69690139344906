@import '../partials/variables';

.quickstart {

    @apply pb-16;
    @apply lg:pb-24;
    // background: #f0f0f0;

    .tabs-wrapper {

        @apply grid;
        @apply md:grid-cols-2;
        @apply gap-6;
        @apply lg:gap-12;

        .tabs-menu {
            
            @apply border-l-2;
            @apply self-start;

            border-color: #DFDFDF;

            li {

                @apply py-2;
                @apply md:py-3;
                @apply lg:py-6;
                margin-left: -2px;

                a {
                    
                    @apply flex;
                    @apply text-sm;
                    @apply md:text-base;
                    @apply lg:text-2xl;
                    @apply font-black;
                    color: #b5b5b5;

                    &[aria-selected="true"] {
                        @apply text-black;

                        span {
                            @apply text-blue;
                            @apply border-blue;
                            
                        }
                    }
                }

                span {
                    @apply inline-block;
                    @apply text-center;
                    @apply w-16;
                    @apply lg:w-24;
                    @apply border-l-2;
                    @apply border-transparent;
                    @apply transition-all;
                    @apply duration-200;
                    @apply ease-in-out;
                }

            }
            
        }

        .tab-item {

            font-size: 14px;

            & > * {
                @apply mb-9;

                &:last-child {
                    @apply mb-0;
                }
            }

            ul {

                li {
                    @apply relative;
                    @apply pl-7;
                    @apply mb-4;
                    &:last-child {
                        @apply mb-0;
                    }

                    &::before {
                        content: '';
                        @apply block;
                        @apply h-1;
                        @apply w-1;
                        @apply absolute;
                        @apply left-0;
                        @apply top-2;
                        @apply bg-blue;

                    }

                }

            }



        }

    }

}