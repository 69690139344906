@import '../partials/variables';

.pricing {
    @apply text-white;
    background: rgb(83,99,241);
    background: -moz-linear-gradient(-45deg,  rgba(83,99,241,1) 0%, rgba(66,162,216,1) 100%);
    background: -webkit-linear-gradient(-45deg,  rgba(83,99,241,1) 0%,rgba(66,162,216,1) 100%);
    background: linear-gradient(-45deg,  rgba(83,99,241,1) 0%,rgba(66,162,216,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5363f1', endColorstr='#42a2d8',GradientType=1 );

    // min-height: 100vh;

    .pricing-card {
        @include pricing-card;
    }

    .pricing-card.pricing-card--focus {

        .ribbon-wrapper {

            @apply block;
            @apply h-36;
            @apply w-36;
            @apply absolute;
            @apply top-0;
            @apply right-0;
            top: -8px;
            right: -8px;
            @apply overflow-hidden;
            @apply pl-2;
            @apply pb-2;

            .ribbon {
                @apply text-white;
                @apply bg-orange;
                @apply leading-8;
                @apply block;
                @apply text-center;
                @apply uppercase;
                @apply font-bold;
                @apply relative;
                @apply select-none;
                font-size: 13px;


                width: 144%;

                transform: rotate(45deg) translateY(-32px);
                transform-origin: top left;


                &::before, &::after {
                    content: '';
                    display: block;
                    @apply absolute;
                    @apply border-red-500;
                    @apply border-b-8;
                    @apply border-r-8;
                    @apply border-t-8;
                    bottom: -10px;

                    border-top-color: transparent;
                    border-bottom-color: transparent;
                }

                &::after {
                    right: 3px;
                    transform: rotate(135deg);
                }

                &::before {
                    left: 0px;
                    transform: rotate(45deg);
                }
            }

        }

        @apply order-1;
        @apply lg:order-2;

        @apply lg:py-24;
        flex-basis: 36%;

        .pricing-features {
            @apply lg:mb-14;
        }

        .pricing-cta {
            min-width: 200px;
            height: 50px;
        }

        z-index: 10;

    }
}